
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
import { authHeader } from "../../../services/auth";

@Component({
  components: {
    CommonUserChoiceModal,
    CommonDeleteModal
  }
})
export default class Appraisal extends Vue {
  public appraisalList = [];
  public appraisalData = {
    name: null,
    website: null,
    type: 1,
    isActive: true,
    createdOn: new Date()
  };
  public isEdit = false;
   public isReset = false;
  public isHide = true;
  public search = "";

  /*************************************************************************
   *                  Adding appraisal                                     *
   *************************************************************************/
  public async saveAppraisal() {
    try {
      this.isHide = false
      const response = await Axios.post(
        BASE_API_URL + "agent/saveAppraisal",
        this.appraisalData,
        {
          headers: authHeader()
        }
      );
      if (response.data.msg === "success") {
        this.$snotify.success(
          this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
        );
         this.isHide = true;
        this.$modal.hide('addAppraisal');
        this.getAppraisalList();
        
      }
    } catch (error) {
      console.log(error);
    }
  }

   public openAppraisalModal() {
     try {
       this.$modal.show('addAppraisal')
      // this.$("#addAppraisal").modal('show')
       this.isEdit=false;
        this.appraisalData={
           name: null,
           website: null,
              type: 1,
            isActive: true,
            createdOn: new Date()
        }


     } catch(error) {
       console.log(error);
     }
   } 

  /*************************************************************************
   *       Fetching appraisal list                                         *
   *************************************************************************/
   public async getAppraisalList(){ 
    try {
       const response = await Axios.get(
        `${BASE_API_URL}agent/getAppraisalList`,
        { 
           params: {
           search: this.isReset ? '' : this.search
           },
            
          headers: authHeader() }
          );
          //  if (response.status === 201) {
          this.isReset = false;
          this.appraisalList = response.data;
         // }
             
    } catch (error) {
      console.log(error);
      this.isReset = false;
    }
  }

  /***************************************************************************
   *              Delete Appraisal                                           *
   ***************************************************************************/
  public async deleteAppraisal(data) {
    try {
      // const response = await Axios.post(
      //   BASE_API_URL + "agent/deleteAppraisal",
      //   { id: data._id }
      // );
      // if (response.data.status == 200) {
      //   this.getAppraisalList();
      // }
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this ${data.name} Appraisal Agent ?`,
        data: { id: data._id },
        apiPath: "agent/deleteAppraisal"
      });
    } catch (error) {
      console.log(error);
    }
  }

  /*****************************************************************************
   *        Function for Activate & De-activate appraisal agent               *
   *****************************************************************************/
  public async changeStatus(data) {
    try {
      // await Axios.post(BASE_API_URL + "agent/changeAppraisalStatus", data);
      // this.getAppraisalList();
      const selectedAgent = data;
      const status = selectedAgent.isActive ? "Active" : "Inactive";
      await this.$refs.refrence["confirmationBox"]({
        msg: `Are you sure want to ${status} the ${selectedAgent.name} Appraisal Agent?`,
        data: {
          data: data
        },
        apiPath: "agent/changeAppraisalStatus"
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  Hiding modal on cancel button
   */
  cancel() {
   this.$modal.hide('addAppraisal')
  }

  mounted() {
    this.getAppraisalList();
  }
}
